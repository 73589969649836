@import "@/assets/variable.scss";

.grid-container {
    width: 100%;
    height: 100%;
}

.drawer-container {
    width: 100%;
    height: calc(100vh - 92px) !important;
}

.date-picker-control {
    width: 380px;
    height: 38px;
}

.select-control {
    max-width: 250px;
}

// fix for calendar view: align weekday names and optimize calendar view
.dx-calendar-views-wrapper {
    > .dx-widget {
        padding-left: 10px;
        padding-right: 10px;
    }
    th {
        text-align: center;
    }
}
.dx-texteditor-input {
    font-size: 1rem;
}
.toolbar-separator {
    width: 10px;
}
