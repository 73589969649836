/*******************************************************************************
* Fonts
*******************************************************************************/

@font-face {
    font-family: TheMixB_plain;
    src: url(fonts/themixb-w5plain-webfont.eot);
    src: url(fonts/themixb-w5plain-webfont.eot?#iefix) format("embedded-opentype"),
        url(fonts/themixb-w5plain-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: TheMixB_semilight;
    src: url(fonts/themixb-w4semilight-webfont.eot);
    src: url(fonts/themixb-w4semilight-webfont.eot?#iefix) format("embedded-opentype"),
        url(fonts/themixb-w4semilight-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: TheMixB_semibold;
    src: url(fonts/themixb-w6semibold-webfont.eot);
    src: url(fonts/themixb-w6semibold-webfont.eot?#iefix) format("embedded-opentype"),
        url(fonts/themixb-w6semibold-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}

/*******************************************************************************
* Variables
*******************************************************************************/

:root {
    /* Color Palette from the RePower Styleguide */
    --repower-color-white: #fff;
    --repower-color-red-100: #ff463c;
    --repower-color-red-90: #ff5950;
    --repower-color-red-80: #ff6b63;
    --repower-color-red-60: #ff908a;
    --repower-color-grey-100: #5a585a;
    --repower-color-grey-80: #7b797b;
    --repower-color-grey-20: #dedede;
    --repower-color-grey-10: #efeeef;
    --repower-color-green-90: #abb51a;
    --repower-color-green-60: #c7ce66;
    --repower-color-yellow-90: #ffbd27;
    --repower-color-yellow-60: #ffd36f;

    /* Background */
    --repower-color-default-bg: var(--repower-color-white);
    --repower-color-danger-bg: var(--repower-color-red-60);
    --repower-color-subtle-bg: var(--repower-color-grey-10);
    --repower-color-success-bg: var(--repower-color-green-60);
    --repower-color-warning-bg: var(--repower-color-yellow-60);

    /* Border */
    --repower-color-default-border: var(--repower-color-grey-80);
    --repower-color-subtle-border: var(--repower-color-grey-20);
    --repower-color-danger-border: var(--repower-color-red-90);
    --repower-color-success-border: var(--repower-color-green-90);
    --repower-color-warning-border: var(--repower-color-yellow-90);

    /* Text */
    --repower-color-default-text: #333;

    /* Primary Button */
    --repower-color-button-primary-text: var(--repower-color-white);
    --repower-color-button-primary-bg: var(--repower-color-red-100);
    --repower-color-button-primary-hover-text: var(--repower-color-white);
    --repower-color-button-primary-hover-bg: var(--repower-color-red-80);

    /* Secondary Button */
    --repower-color-button-secondary-text: var(--repower-color-white);
    --repower-color-button-secondary-bg: var(--repower-color-grey-100);
    --repower-color-button-secondary-hover-text: var(--repower-color-white);
    --repower-color-button-secondary-hover-bg: var(--repower-color-grey-80);

    /* Link */
    --repower-color-link-text: var(--repower-color-red-100);
    --repower-color-link-hover-text: var(--repower-color-red-80);

    /* Box Shadow */
    --repower-box-shadow-default: var(--repower-color-grey-10) 0px 0px 2px;
}

/*******************************************************************************
* General
*******************************************************************************/

html {
    font-size: 16px; /* Do not change this value, it correspond to 1rem value in child element */
}

body {
    font-family: TheMixB_plain, sans-serif;
    color: var(--repower-color-default-text);
}

a {
    color: var(--repower-color-link-text);
}

a:hover {
    color: var(--repower-color-link-hover-text);
}

.repower hr {
    border: 0 none;
    height: 1px;
    background-color: var(--repower-color-default-border);
}

/*******************************************************************************
* Side Menu
*******************************************************************************/

#sideMenuWrapper {
    width: 350px;
    border-left: 1px solid var(--repower-color-default-border);
    border-right: 1px solid var(--repower-color-default-border);
    background-color: var(--repower-color-subtle-bg);
}

/*******************************************************************************
* Paginator
*******************************************************************************/

.page-link {
    color: var(--repower-color-link-text);
    background-color: var(--repower-color-default-bg);
}

.page-link:hover {
    color: var(--repower-color-link-text);
}

.page-item.active .page-link {
    background-color: var(--repower-color-link-text);
    border-color: var(--repower-color-link-text);
}

/*******************************************************************************
* Background Overwrites
*******************************************************************************/

.repower .bg-danger {
    background-color: var(--repower-color-danger-bg) !important;
}

.repower .bg-light {
    background-color: var(--repower-color-subtle-bg) !important;
}

.repower .bg-success {
    background-color: var(--repower-color-success-bg) !important;
}

.repower .bg-warning {
    background-color: var(--repower-color-warning-bg) !important;
}

.repower .bg-light hr {
    background-color: var(--repower-color-subtle-border);
}

.repower .bg-danger hr,
.repower .bg-success hr,
.repower .bg-warning hr {
    background-color: var(--repower-color-default-border);
}

/*******************************************************************************
* Button Overwrites
*******************************************************************************/

.btn.disabled,
.btn:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}

.btn-primary,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    border-color: var(--repower-color-button-primary-bg);
    color: var(--repower-color-button-primary-text);
    background-color: var(--repower-color-button-primary-bg);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:disabled,
.btn-primary.disabled {
    border-color: var(--repower-color-button-primary-bg);
    color: var(--repower-color-button-primary-hover-text);
    background-color: var(--repower-color-button-primary-hover-bg);
}

.btn-outline-primary {
    border-color: var(--repower-color-button-primary-bg);
    color: var(--repower-color-button-primary-bg);
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
    border-color: var(--repower-color-button-primary-bg);
    color: var(--repower-color-button-primary-text);
    background-color: var(--repower-color-button-primary-bg);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
    border-color: var(--repower-color-button-primary-bg);
    color: var(--repower-color-button-primary-hover-text);
    background-color: var(--repower-color-button-primary-hover-bg);
}

.btn-secondary,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
    border-color: var(--repower-color-button-secondary-bg);
    color: var(--repower-color-button-secondary-text);
    background-color: var(--repower-color-button-secondary-bg);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:disabled,
.btn-secondary.disabled {
    border-color: var(--repower-color-button-secondary-bg);
    color: var(--repower-color-button-secondary-hover-text);
    background-color: var(--repower-color-button-secondary-hover-bg);
}

/*******************************************************************************
* Card Overwrites
*******************************************************************************/

.repower .card {
    border-color: var(--repower-color-subtle-border);
    background-color: var(--repower-color-default-bg);
    box-shadow: var(--repower-box-shadow-default);
}

.repower .card .card-header,
.repower .card .card-footer {
    border-color: var(--repower-color-subtle-border);
    background-color: var(--repower-color-subtle-bg);
}

.repower .card hr {
    border: 0 none;
    height: 1px;
    background-color: var(--repower-color-subtle-border);
}

.repower .card .bg-danger hr,
.repower .card .bg-success hr,
.repower .card .bg-warning hr {
    background-color: var(--repower-color-default-border);
}

/*******************************************************************************
* Dialog Overwrites
*******************************************************************************/

.dg-btn {
    border-color: var(--repower-color-button-primary-bg);
    color: var(--repower-color-button-primary-text);
    background-color: var(--repower-color-button-primary-bg);
}

.dg-btn-loader .dg-circle {
    background-color: var(--repower-color-button-primary-bg);
}

/*******************************************************************************
* DevExpress Grid Overwrites
*******************************************************************************/

/* Column-header filter should always be on the right side */
div.dx-column-indicators {
    float: right !important;
}

/* Column-header text should always be on the left side */
td[role=columnheader] {  
    text-align: left !important  
} 

/*******************************************************************************
* Misc
*******************************************************************************/

.pointer {
    cursor: pointer;
}

.re-icon {
    color: var(--repower-color-red-100);
}

.textDeleted {
    text-decoration: line-through;
}

.text-black-20 {
    color: var(--repower-color-grey-20);
}
