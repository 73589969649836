@import "@/assets/variable.scss";

#ReHeader {
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 100%;
    padding: 0;
    margin: auto;
}

.router-link-active {
    color: rgb(0, 0, 0, 0.9) !important;
}
