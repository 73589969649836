<template>
    <div v-if="mainDevice && order && localMainDevice">
        <exec-meter-info
            v-show="this.localMainDevice"
            class="mt-3"
            :mainDevice="this.localMainDevice"
            :order="order"
            @showRelatedDeviceInfo="setShowRelatedDeviceInfo"
            :category="category"
        ></exec-meter-info>
        <div class="d-flex align-items-space-between flex-column">
            <!-- in Category Ablesen or Wechseln -->
            <div v-if="isAblesung || isWechseln" class="button-container">
                <!-- Fernablesung -->
                <template v-if="mainDevice.telemeteringType > 0">
                    <b-row class="mt-2 no-gutters ml-2" v-if="auftragDetail712">
                        <b-col class="col text-left" cols="1">
                            <font-awesome-icon :icon="['fas', 'circle']" :class="compFernablesungStatusVariant" />
                        </b-col>
                        <b-col cols="11" style="font-size:0.875em">
                            Fernablesung:
                            <span v-if="localAuftragDetail712.status === 203">
                                {{ moment(zfaObject.readingDate).format("HH:mm, DD.MM.YYYY") }}</span
                            >
                            <span v-else>
                                {{ fernablesungStatus }}
                            </span>
                            <div
                                class="d-flex flex-row justify-content-between"
                                v-if="localAuftragDetail712?.status < 0 || localAuftragDetail712?.status >= 500"
                            >
                                <b-button
                                    :disabled="isLoading"
                                    :variant="isAblesung ? 'primary' : 'secondary'"
                                    @click="onClickSetFernablesungStatus()"
                                    class="button-ablesung"
                                    >Fehler ignorieren<b-spinner v-if="isLoading" small />
                                </b-button>
                            </div>
                            <div class="d-flex flex-row justify-content-between">
                                <b-button
                                    :disabled="isLoading"
                                    :variant="isAblesung ? 'primary' : 'secondary'"
                                    class="button-ablesung"
                                    @click="onClickShowModal()"
                                    >(Erneut) Starten <b-spinner v-if="isLoading" small />
                                </b-button>
                                <zfa-button-job-status
                                    ref="zfaButtonJobStatusComponent"
                                    class="button-ablesung"
                                    title="Status prüfen"
                                    :variant="isAblesung ? 'primary' : 'secondary'"
                                    v-if="order && mainDevice.devicePool"
                                    v-bind:auftrag-id="order.auftragID"
                                    v-bind:device-pool-id="mainDevice.devicePool.devicePoolID"
                                    v-bind:device-id="mainDevice.deviceID"
                                    v-bind:auto-start="false"
                                    v-on:checkZfaJobStatusDone="onCheckZfaJobStatusDone"
                                ></zfa-button-job-status>
                            </div>
                            <b-modal
                                :id="localMainDevice.deviceID.toString()"
                                title="Fernablesung bereits gestartet"
                                cancel-title="Abbrechen"
                                ok-title="Nochmals ablesen"
                                @ok="onClickFernablesung()"
                                :ok-disabled="false"
                            >
                                <b-alert show variant="warning" class="p-1 my-2 font-italic small">
                                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                                    Es wurde bereits eine Fernablesung gestartet. Diese war bereits erfolgreich oder ist
                                    noch in Ausführung. Möchten Sie die Fernablesung erneut starten?<br /><br />
                                    <strong>Bitte beachte, dass der Meter aktiv sein muss.</strong>
                                </b-alert>
                            </b-modal>
                        </b-col>
                    </b-row>
                </template>
                <!-- Manual/Optical reading -->
                <b-row class="mt-2 no-gutters ml-2">
                    <b-col class="col text-left" cols="1">
                        <font-awesome-icon :icon="['fas', 'circle']" :class="compManuelleAblesungStatusVariant" />
                    </b-col>
                    <b-col cols="11" v-if="localAuftragDetail714" style="font-size:0.875em">
                        Ablesung vor Ort:
                        <span v-if="[202, 203].includes(localAuftragDetail714.status)">
                            {{ moment(localAuftragDetail714.executionTime).format("HH:mm, DD.MM.YYYY") }}</span
                        ><template v-if="[202, 203].includes(localAuftragDetail714.status)">
                            ({{ localAuftragDetail714.status === 202 ? "opt." : "man." }})</template
                        >
                        <span v-else>
                            nein
                        </span>
                        <div>
                            <b-button
                                v-if="localAuftragDetail714.status != 202"
                                :variant="[202, 203].includes(localAuftragDetail714.status) ? 'secondary' : 'primary'"
                                @click="onClickMeterRead()"
                                class="button-ablesung"
                            >
                                Manuell erfassen
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <b-button variant="primary" @click="onClickZaehlerReplace()" v-if="isWechseln" class="m-2">
                    Zählerwechseln
                </b-button>
            </div>
            <!-- in Category Verbinden mit Smartmanager -->
            <div v-if="isVerbSmartmanager" class="button-container">
                <b-button variant="primary" @click="onClickZaehlerConfig724(mainDevice.deviceID)" class="m-2">
                    Zähler mit Smartmanager verbinden
                </b-button>
                <b-button @click="onClickZaehlerReplace()" class="m-2" v-if="showRelatedDeviceInfo">
                    Zählerwechsel rückgängig machen
                </b-button>
            </div>
            <!-- in Category Wandler -->
            <div v-if="isWandlerCategory" class="button-container">
                <b-button variant="primary" @click="onClickStartWandlerProcess(mainDevice.deviceID)" class="m-2">
                    Wandlerprozess starten
                </b-button>
            </div>
            <!-- in Category Funktionstest -->
            <div v-if="isFunktionstest" class="button-container">
                <b-button @click="onClickZaehlerConfig724()" class="m-2">
                    Verbindung zum Smartmanager ändern
                </b-button>
                <b-button variant="primary" @click="onClickZaehlerConfig751()" class="m-2">
                    Funktionstest durchführen
                </b-button>
            </div>
            <!-- in Category Abgeschlossen-->
            <div v-if="isAbgeschlossen" class="button-container">
                <b-button @click="onClickZaehlerConfig751()" class="m-2">
                    Funktionstest aufrufen
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import ExecMeterInfo from "@/components/execution/ExecMeterInfo.vue";
import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicesAPI from "@/services/api/devices.api";
import zfaService from "@/services/zfaService";
import * as zfaUtils from "@/utils/ZfaUtils";
import ZfaButtonJobStatus from "@/components/execution/ZfaButtonJobStatus";
import constants from "@/constants/constants";

export default {
    name: "ExecMeterCard",
    components: {
        ExecMeterInfo,
        ZfaButtonJobStatus,
    },
    data() {
        return {
            showRelatedDeviceInfo: false,
            localAuftragDetail712: this.auftragDetail712,
            localAuftragDetail714: this.auftragDetail714,
            localMainDevice: null,
            isLoading: false,
        };
    },
    props: {
        mainDevice: {
            type: Object,
            required: true,
        },
        order: {
            type: Object,
            required: true,
        },
        category: {
            type: String,
            required: true,
        },
        // Manual/Optical reading
        auftragDetail712: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        // Fernablesung
        auftragDetail714: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    async mounted() {
        this.localMainDevice = await this.getMainDevice();
    },
    computed: {
        compFernablesungStatusVariant() {
            return this.getColorByZaehlerstandablesungStatus(this.fernablesungStatus);
        },
        compManuelleAblesungStatusVariant() {
            return this.getColorByZaehlerstandablesungStatus(this.manuelleAblesungStatus);
        },
        fernablesungStatus() {
            const status = this.localAuftragDetail712?.status;

            switch (status) {
                case -1:
                    return "fehlerhaft";
                case 204:
                    return "fehlerhaft (ignoriert)";
                case 203:
                    return "ja";
                case 202:
                    return "gestartet";
                default:
                    return "nein";
            }
        },
        manuelleAblesungStatus() {
            const status = this.localAuftragDetail714?.status;

            switch (status) {
                case 202:
                    return "ja";
                case 203:
                    return "ja";
                default:
                    return "nein";
            }
        },
        isAblesung() {
            return this.category == "Ablesung";
        },
        isWechseln() {
            return this.category == "Wechseln";
        },
        isFunktionstest() {
            return this.category == "Funktionstest";
        },
        isAbgeschlossen() {
            return this.category == "Abgeschlossen";
        },
        isVerbSmartmanager() {
            return this.category == "Verbinden mit Smartmanager";
        },
        isWandlerCategory() {
            return this.category == "Wandler";
        },
        zfaObject() {
            return zfaUtils.parseAuftragDetailValue(this.auftragDetail712?.value);
        },
        moment() {
            return moment;
        },
    },
    methods: {
        setShowRelatedDeviceInfo(showRelatedDeviceInfo) {
            this.showRelatedDeviceInfo = showRelatedDeviceInfo;
        },
        async getMainDevice() {
            if (this.isWandlerCategory) {
                return this.fetchRelatedDeviceDetails(this.mainDevice);
            }
            return this.mainDevice;
        },
        async fetchRelatedDeviceDetails() {
            const deviceResp = await devicesAPI.getRelatedDevice({
                id: this.mainDevice.deviceID,
                devicePoolId: this.mainDevice.devicePoolID,
                auftragId: this.order.auftragID,
                includeDeviceInfos: true,
            });
            //The deviceID associated with the auftragDetails of this step
            //is the same as that of the old meter. We therefore retrieve the
            //information from the new installed meter.
            return deviceResp?.data?.newDevice;
        },
        getColorByZaehlerstandablesungStatus(status) {
            if (status === "ja") {
                return "text-success";
            }
            if (status == "nein") {
                return "text-danger";
            }
            if (status == "gestartet" || status == "fehlerhaft (ignoriert)") {
                return "text-warning";
            }
            return "text-danger";
        },
        async onCheckZfaJobStatusDone() {
            try {
                this.localAuftragDetail712 = await auftragDetailsAPI.getByOrderIdAndStep(
                    this.$route.params.orderId,
                    "7-1-2",
                    this.$route.params.devicePoolId,
                    this.mainDevice.deviceID
                );
                this.$emit("checkZfaJobStatusDoneMeterCard", {
                    auftragID: this.$route.params.orderId,
                    devicePoolID: this.$route.params.devicePoolId,
                    deviceID: this.mainDevice?.deviceId,
                });
            } finally {
                this.isLoadingAuftragDetails712 = false;
            }
        },
        async onClickFernablesung() {
            this.isLoadingRemote = true;
            this.isLoading = true;
            try {
                let result = await zfaService.executeZfaJobReadSaveToStep712(
                    this.$route.params.orderId,
                    this.$route.params.devicePoolId,
                    this.mainDevice
                );
                if (result?.code === 0) {
                    this.$bvToast.toast(`Fernablesung gestartet.`, {
                        title: "Success",
                        variant: "success",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: false,
                    });
                }

                await this.onCheckZfaJobStatusDone();
                // Simulate click on button "Check ZFA"
                await this.$refs.zfaButtonJobStatusComponent.checkJobStatus();
            } finally {
                this.isLoadingRemote = false;
                this.isLoading = false;
            }
        },
        async onClickSetFernablesungStatus() {
            this.isLoading = true;
            try {
                // Only update Status
                await auftragDetailsAPI.patchValueAndStatusOnDiff(
                    this.auftragDetail712.auftragDetailID,
                    null,
                    null,
                    constants.auftragDetailStatus.DONE_CONDITION_3
                );
            } finally {
                this.isLoading = false;
                await this.onCheckZfaJobStatusDone();
            }
        },
        async onClickShowModal() {
            this.localAuftragDetail712 = await auftragDetailsAPI.getByOrderIdAndStep(
                this.$route.params.orderId,
                "7-1-2",
                this.$route.params.devicePoolId,
                this.mainDevice.deviceID
            );
            if ([202, 203, 204].includes(this.localAuftragDetail712.status)) {
                this.$bvModal.show(this.localMainDevice.deviceID.toString());
            } else {
                await this.onClickFernablesung();
            }
        },
        async onClickZaehlerReplace() {
            // navigate to step 7-2-1 or 7-2-0 (ex: 7-4-1)
            if (this.mainDevice.smartType === 0) {
                // old meter
                const auftragDetail721 = await auftragDetailsAPI.getByOrderIdAndStep(
                    this.order.auftragID,
                    "7-2-1",
                    this.mainDevice.devicePoolID,
                    this.mainDevice.deviceID
                );
                await this.$router.push({
                    name: "step-7-2-1",
                    params: {
                        deviceId: this.mainDevice.devicePoolID,
                        devicePoolId: this.mainDevice.devicePoolID,
                        auftragItemId: auftragDetail721.auftragItemID,
                    },
                });
            } else {
                // SMET
                const auftragDetail720 = await auftragDetailsAPI.getByOrderIdAndStep(
                    this.order.auftragID,
                    "7-2-0",
                    this.mainDevice.devicePoolID,
                    this.mainDevice.deviceID
                );
                await this.$router.push({
                    name: "step-7-2-0",
                    params: {
                        deviceId: this.mainDevice.deviceID,
                        devicePoolId: this.mainDevice.devicePoolID,
                        auftragItemId: auftragDetail720.auftragItemID,
                    },
                });
            }
        },
        async onClickMeterRead() {
            // navigate to step 7-1-3
            const auftragDetail = await auftragDetailsAPI.getByOrderIdAndStep(
                this.order.auftragID,
                "7-1-3",
                this.mainDevice.devicePoolID,
                this.mainDevice.deviceID
            );
            await this.$router.push({
                name: "step-7-1-3",
                params: {
                    deviceId: this.mainDevice.deviceID,
                    devicePoolId: this.mainDevice.devicePoolID,
                    auftragItemId: auftragDetail.auftragItemID,
                },
            });
        },
        async onClickStartWandlerProcess(deviceId) {
            // navigate to step 7-3-1
            const auftragDetail731 = await auftragDetailsAPI.getByOrderIdAndStep(
                this.order.auftragID,
                "7-3-1",
                this.mainDevice.devicePoolID,
                deviceId
            );
            await this.$router.push({
                name: "step-7-3-1",
                params: {
                    devicePoolId: this.mainDevice.devicePoolID,
                    auftragItemId: auftragDetail731.auftragItemID,
                },
            });
        },
        async onClickZaehlerConfig724() {
            // navigate to step 7-2-4
            const auftragDetail724 = await auftragDetailsAPI.getByOrderIdAndStep(
                this.order.auftragID,
                "7-2-4",
                this.mainDevice.devicePoolID,
                this.mainDevice.deviceID
            );
            await this.$router.push({
                name: "step-7-2-4",
                params: {
                    deviceId: this.mainDevice.deviceID,
                    devicePoolId: this.mainDevice.devicePoolID,
                    auftragItemId: auftragDetail724.auftragItemID,
                },
            });
        },
        async onClickZaehlerConfig751() {
            // navigate to step 7-5-1
            const auftragDetail = await auftragDetailsAPI.getByOrderIdAndStep(
                this.order.auftragID,
                "7-5-1",
                this.mainDevice.devicePoolID,
                this.mainDevice.deviceID
            );
            await this.$router.push({
                name: "step-7-5-1",
                params: {
                    deviceId: this.mainDevice.deviceID,
                    devicePoolId: this.mainDevice.devicePoolID,
                    auftragItemId: auftragDetail.auftragItemID,
                },
            });
        },
    },
};
</script>
<style scoped>
.button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.button-ablesung {
    min-width: 140px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 6px;
}
</style>
