<template>
    <Main>
        <ExecHeader :devicePool="devicePool" :order="order" />
        <div v-if="order?.auftragID" class="d-flex justify-content-between mt-3">
            <button @click="navigateToREtasksBridgeApp" class="btn btn-primary re-operation-button-top">
                Optische Ablesung
            </button>
            <zfa-button-job-status
                title="Fernablesung Status prüfen"
                :variant="'primary'"
                v-bind:autoStart="true"
                v-if="metersWithTelemeteringCount > 0 && devicePool"
                v-bind:auftrag-id="order.auftragID"
                v-bind:device-pool-id="devicePool.devicePoolID"
                v-on:checkZfaJobStatusDone="onCheckZfaJobStatusDone"
                class="ml-2 re-operation-button-top"
            />
        </div>
        <DxPopup
            :visible="showPopUp"
            :width="300"
            :height="165"
            title="Ablesung"
            content-template="content"
            :dragEnabled="true"
            :enableBodyScroll="false"
            :showCloseButton="true"
        >
            <template content>
                <p>Ablesung abgeschlossen?</p>
                <div class="d-flex justify-content-end">
                    <b-button variant="primary" @click="refreshDataAsync">
                        Ja
                    </b-button>
                </div>
            </template>
        </DxPopup>
        <template v-if="metersByCategory && order && !isLoadingAuftragDetails712 && !isLoadingAuftragDetails714">
            <Box
                v-for="(e, category) in metersByCategory"
                :key="`d_${category}`"
                :headerText="category"
                :headerInfo="getSectionStatus(e.devices, e.ammountOfCompletedMeters, category)"
                :headerIcon="getSectionIcon(e.devices, e.ammountOfCompletedMeters, category)"
            >
                <div v-for="(d, index) in e.devices" :key="`d_${index}`" class="mb-3 mt-3">
                    <HorizontalSeparator v-if="index > 0" class="horizontal-separator-container" />
                    <exec-meter-card
                        :mainDevice="d"
                        :order="order"
                        :category="category"
                        :auftragDetail712="auftragDetails712.find((obj) => obj.deviceID === d.deviceID)"
                        :auftragDetail714="auftragDetails714.find((obj) => obj.deviceID === d.deviceID)"
                        v-on:checkZfaJobStatusDoneMeterCard="onCheckZfaJobStatusDone"
                    />
                </div>
            </Box>
        </template>
        <b-spinner v-else small />
    </Main>
</template>

<script>
import ExecHeader from "@/components/execution/ExecHeader";
import ExecMeterCard from "@/components/execution/ExecMeterCard.vue";
import ZfaButtonJobStatus from "@/components/execution/ZfaButtonJobStatus";
import constants from "@/constants/constants";
import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import auftragsAPI from "@/services/api/auftrags.api";
import devicePoolsAPI from "@/services/api/devicepools.api";
import devicesAPI from "@/services/api/devices.api";
import unimodRawReadsApi from "@/services/api/unimodRawReads.api";
import _ from "lodash";
import { DxPopup } from "devextreme-vue";
import { mapGetters } from "vuex";
import metersByCategoryApi from "@/services/api/metersByCategory.api";

export default {
    name: "ExecutionMeters",
    components: {
        ZfaButtonJobStatus,
        ExecHeader,
        ExecMeterCard,
        DxPopup,
    },
    data() {
        return {
            auftragDetails712: [],
            auftragDetails714: [],
            metersNotInOrder: [],
            errorMeters: [],
            devicePool: null,
            devices: null,
            isLoadingAuftragDetails712: true,
            isLoadingAuftragDetails714: true,
            order: null,
            orderId: null,
            showPopUp: false,
            metersByCategory: {
                Ablesung: [],
                Wechseln: [],
                "Verbinden mit Smartmanager": [],
                Wandler: [],
                Funktionstest: [],
                Abgeschlossen: [],
            },
            totalDevices: 0,
            totalDevicesWithWandler: 0,
            closedErrors: [],
            toastWarningClosedPrefix: "toastWarningClosedForMetersNotInOrder-",
        };
    },
    async mounted() {
        this.devicePoolId = this.$route.params.devicePoolId;
        this.orderId = this.$route.params.orderId;
        this.loadMetersNotInOrderAndErrorMeters();
        this.loadMetersByCategory();
        auftragsAPI.getSingle(this.orderId).then((resp) => {
            this.order = resp.data;
        });
        devicePoolsAPI.getSingle(this.devicePoolId).then((resp) => {
            this.devicePool = resp.data;
        });
        this.devices = await devicesAPI.get({
            devicePoolID: this.devicePoolId,
            type__in: "Elektrizitätszähler",
            includeDeviceInfos: true,
        });
        auftragDetailsAPI
            .getByOrderIdAndStep(this.$route.params.orderId, "7-1-2", this.devicePoolId, null, null, true)
            .then((resp) => {
                this.auftragDetails712 = resp;
            })
            .finally(() => {
                this.isLoadingAuftragDetails712 = false;
            });
        auftragDetailsAPI
            .getByOrderIdAndStep(this.$route.params.orderId, "7-1-4", this.devicePoolId, null, null, true)
            .then((resp) => {
                this.auftragDetails714 = resp;
            })
            .finally(() => {
                this.isLoadingAuftragDetails714 = false;
            });
    },
    computed: {
        ...mapGetters(["oidcAccessToken", "oidcRefreshToken"]),
        metersWithTelemeteringCount() {
            if (this.devices && this.devices.length > 0) {
                const smartMeters = _.filter(this.devices, function(device) {
                    return device.telemeteringType > 0;
                });
                return smartMeters.length;
            }
            return 0;
        },
        alertMessage() {
            if (this.metersNotInOrder.data.length > 0) {
                return this.metersNotInOrder.data.length > 1
                    ? `Die abgelesenen Zähler (${this.allFactoryNr}) sind an diesem Gerätestandort im Verrechnungssystem
                        nicht installiert. Bitte wechseln Sie zum entsprechenden Gerätestandort oder setzen Sie sich mit dem
                        Backoffice in Verbindung!`
                    : `Der abgelesene Zähler (${this.allFactoryNr}) ist an diesem Gerätestandort im Verrechnungssystem
                        nicht installiert. Bitte wechseln Sie zum entsprechenden Gerätestandort oder setzen Sie sich mit dem
                        Backoffice in Verbindung!`;
            }
            return "";
        },
        allFactoryNr() {
            return this.metersNotInOrder.data
                .map((entry) => {
                    if (entry.internalNo) {
                        return `(WerkNr. ${entry.internalNo}, FabrikNr. ${entry.factoryNo})`;
                    } else {
                        return `FabrikNr. ${entry.factoryNo}`;
                    }
                })
                .join(", ");
        },
    },
    methods: {
        async refreshDataAsync() {
            this.$bvToast.hide();
            this.devices = [];
            this.devices = await devicesAPI.get({
                devicePoolID: this.devicePoolId,
                type__in: "Elektrizitätszähler",
                includeDeviceInfos: true,
            });
            this.loadMetersByCategory();
            this.loadMetersNotInOrderAndErrorMeters();
        },
        loadMetersNotInOrderAndErrorMeters() {
            this.initializeClosedErrors();
            this.showPopUp = false;
            unimodRawReadsApi.getMetersNotInOrder(this.orderId, this.devicePoolId).then((resp) => {
                this.metersNotInOrder = resp;
                if (this.metersNotInOrder.data?.length > 0) {
                    // Check if the unimod warning toast was already closed by the user
                    const toastWarningClosedFlag = localStorage.getItem(
                        this.toastWarningClosedPrefix + this.orderId + "-" + this.devicePoolId
                    );
                    if (!toastWarningClosedFlag) {
                        this.handleUniModWarning();
                    }
                }
            });
            unimodRawReadsApi.getErrorMeters(this.orderId, this.devicePoolId).then((resp) => {
                this.errorMeters = resp;
                this.errorMeters?.data?.forEach((meter) => {
                    const $closeButton = this.$createElement(
                        "b-button",
                        {
                            on: {
                                click: () => {
                                    this.closeError(`${meter.unimodRawReadID}`);
                                    this.$bvToast.hide(`${meter.unimodRawReadID}`);
                                },
                            },
                            class: ["border-transparent"],
                            style: {
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                zIndex: "999",
                                background: "transparent",
                                border: "none",
                                color: "gray",
                                boxShadow: "none",
                            },
                        },
                        [
                            this.$createElement("i", {
                                class: "fa fa-close",
                            }),
                        ]
                    );

                    if (!this.isErrorClosed(`${meter.unimodRawReadID}`)) {
                        this.$bvToast.toast([$closeButton, `${meter.detail}`], {
                            id: `${meter.unimodRawReadID}`,
                            title: `Fehler: RawReadID ${meter.unimodRawReadID}`,
                            variant: "danger",
                            toaster: "b-toaster-bottom-right",
                            noAutoHide: true,
                            appendToast: true,
                            noCloseButton: true,
                        });
                    }
                });
            });
        },
        handleUniModWarning() {
            var closeWarningButton = this.$createElement(
                "b-button",
                {
                    on: {
                        click: () => {
                            localStorage.setItem(
                                this.toastWarningClosedPrefix + this.orderId + "-" + this.devicePoolId,
                                true
                            );
                            this.$bvToast.hide(this.toastWarningClosedPrefix + this.orderId + "-" + this.devicePoolId);
                        },
                    },
                    class: ["border-transparent"],
                    style: {
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        zIndex: "999",
                        background: "transparent",
                        border: "none",
                        color: "gray",
                        boxShadow: "none",
                    },
                },
                [
                    this.$createElement("i", {
                        class: "fa fa-close",
                    }),
                ]
            );
            this.$bvToast.toast([closeWarningButton, this.alertMessage], {
                id: this.toastWarningClosedPrefix + this.orderId + "-" + this.devicePoolId,
                title: "Warnung",
                variant: "warning",
                toaster: "b-toaster-bottom-right",
                noAutoHide: true,
                appendToast: true,
                noCloseWarningButton: true,
            });
        },
        isErrorClosed(id) {
            return this.closedErrors.includes(id);
        },
        closeError(id) {
            if (!this.isErrorClosed(id)) {
                this.closedErrors.push(id);
                localStorage.setItem("closedErrors", JSON.stringify(this.closedErrors));
            }
        },
        initializeClosedErrors() {
            const storedClosedErrors = localStorage.getItem("closedErrors");
            if (storedClosedErrors) {
                this.closedErrors = JSON.parse(storedClosedErrors);
            }
        },
        navigateToREtasksBridgeApp() {
            this.showPopUp = true;
            const url = `${constants.env.BRIDGE_ROOT_URL}/?auftragUD=${this.orderId}&devicePoolID=${this.devicePoolId}&refreshToken=${this.oidcRefreshToken}&accessToken=${this.oidcAccessToken}`;

            window.open(url, "_blank");
        },
        loadMetersByCategory() {
            metersByCategoryApi.getMetersByCategory(this.orderId, this.devicePoolId).then((resp) => {
                this.metersByCategory = {
                    Ablesung: resp.data.ablesung,
                    Wechseln: resp.data.wechseln,
                    "Verbinden mit Smartmanager": resp.data.verbSmartManager,
                    Wandler: resp.data.wandlerprocess,
                    Funktionstest: resp.data.funktionstest,
                    Abgeschlossen: resp.data.abgeschlossen,
                };
                this.totaleDevices = resp.data.totalCount;
                this.totalDevicesWithWandler = resp.data.devicesWithWandlerCount;
            });
        },
        areAllDevicesAbgeschlossen(currentCount, category) {
            return currentCount === this.totaleDevices && this.isLastCategory(category);
        },
        isLastCategory(category) {
            return category === "Abgeschlossen";
        },
        isMeterCountLowerOrEqualToTotalCountAndGreaterThanZero(currentCount) {
            /* Return true if both are true:
                Ammount of meters in this step are lower or equal to the total ammount of meters in all steps
                Ammount of meters in this step are higher than 0
            */
            return currentCount <= this.totaleDevices && currentCount > 0;
        },
        isMeterCountEqualToZeroAndNotEqualToAmmountOfCompletedMeters(currentCount, ammountOfCompletedMeters) {
            /* Return true if both are true:
                Ammount of meters in this step are equal to 0
                Ammount of meters in this step are not equal to the total ammount of meters in all steps
            */
            return currentCount == 0 && currentCount != ammountOfCompletedMeters;
        },
        getSectionStatus(device, ammountOfCompletedMeters, category) {
            /* Show meter-status (x / y Zähler) if at least one is true AND meter-icon is currently not displayed for this step:
                Ammount of meters in this step are lower or equal to the total ammount of meters in all steps and are higher than 0
                Category is 'Abgeschlossen'
                AmmountOfCompletedMeters is equal to ammount of meters in this step
                Ammount of meters in this step are equal to 0 and not equal to the total ammount of meters in all steps
            */

            // set currentCount to 0 if device is null or undefined
            const currentCount = device?.length ?? 0;
            if (
                (this.isMeterCountLowerOrEqualToTotalCountAndGreaterThanZero(currentCount) ||
                    this.isLastCategory(category) ||
                    currentCount == ammountOfCompletedMeters ||
                    this.isMeterCountEqualToZeroAndNotEqualToAmmountOfCompletedMeters(
                        currentCount,
                        ammountOfCompletedMeters
                    )) &&
                !this.isSectionIconDisplayed(currentCount, ammountOfCompletedMeters, category)
            ) {
                if (category.toLowerCase() === "wandler") {
                    return `${currentCount} von ${this.totalDevicesWithWandler}`;
                } else {
                    return `${currentCount} von ${this.totaleDevices}`;
                }
            }
        },
        isSectionIconDisplayed(device, ammountOfCompletedMeters, category) {
            // check if meter-icon (circle-check) is displayed for this step
            const meterIconResponse = this.getSectionIcon(device, ammountOfCompletedMeters, category);
            const defaultMeterIcon = ["fas", "circle-check"];
            return (
                meterIconResponse?.length === defaultMeterIcon.length &&
                meterIconResponse.every((element, index) => element === defaultMeterIcon[index])
            );
        },
        getSectionIcon(device, ammountOfCompletedMeters, category) {
            /* Show meter-icon (circle-check) if all are true:
                Ammount of meters in this step are equal to 0
                AmmountOfCompletedMeters is equal to the total ammount of meters in all steps
                Category is NOT 'Abgeschlossen'
            */

            // set currentCount to 0 if device is null or undefined
            const currentCount = device?.length ?? 0;
            if (
                currentCount === 0 &&
                ammountOfCompletedMeters === this.totaleDevices &&
                !this.isLastCategory(category)
            ) {
                return ["fas", "circle-check"];
            }
        },
        onCheckZfaJobStatusDone() {
            auftragDetailsAPI
                .getByOrderIdAndStep(this.$route.params.orderId, "7-1-2", this.devicePoolId, null, null, true)
                .then((resp) => {
                    this.auftragDetails712 = resp;
                    this.loadMetersByCategory(); // reload meters by category after checking job status (avoid hard refresh) #20468
                });
        },
    },
};
</script>
<style scoped>
.horizontal-separator-container {
    margin-left: -1em;
    margin-right: -1em;
}
.re-operation-button-top {
    font-size: 15px;
    padding-left: 6px;
    padding-right: 6px;
}
</style>
